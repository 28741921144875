import React, { createContext, useContext, useReducer, useEffect } from 'react';
import i18n from 'i18next';

const AppContext = createContext();

const initialState = {
  user: null,
  language: 'ptbr',
  theme: 'light',
  // Add other global state properties as needed
};

function appReducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    case 'SET_THEME':
      return { ...state, theme: action.payload };
    // Add other action types as needed
    default:
      return state;
  }
}

export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    // Initialize language from i18n
    dispatch({ type: 'SET_LANGUAGE', payload: i18n.language });
  }, []);

  useEffect(() => {
    // Change i18n language when state.language changes
    i18n.changeLanguage(state.language);
  }, [state.language]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}