import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient, QueryCommand } from '@aws-sdk/lib-dynamodb';

// Configure AWS Client
const client = new DynamoDBClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  }
});

const docClient = DynamoDBDocumentClient.from(client);

// Define supported languages
const SUPPORTED_LANGUAGES = ['ptbr', 'en', 'es'];
const DEFAULT_LANGUAGE = 'ptbr';
const FALLBACK_LANGUAGE = 'en';

// Initialize with basic structure for all supported languages
const initialResources = SUPPORTED_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = { translation: {} };
  return acc;
}, {});

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: initialResources,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    },
    load: 'languageOnly',
    debug: process.env.NODE_ENV === 'dev',
    keySeparator: false,
    nsSeparator: false,
    returnEmptyString: false,
    returnNull: false,
    // Add missing languages support
    supportedLngs: SUPPORTED_LANGUAGES,
    preload: SUPPORTED_LANGUAGES
  });

const fetchTranslationsFromDynamoDB = async (language) => {
  if (!SUPPORTED_LANGUAGES.includes(language)) {
    throw new Error(`Unsupported language: ${language}`);
  }

  try {
    const command = new QueryCommand({
      TableName: 'translations',
      KeyConditionExpression: 'lang_code = :lang',
      ExpressionAttributeValues: {
        ':lang': language
      }
    });

    console.log(`Fetching translations for language: ${language}`);
    const result = await docClient.send(command);
    
    if (!result.Items || result.Items.length === 0) {
      console.warn(`No translations found for language: ${language}`);
      return null;
    }

    console.log(`Successfully fetched ${result.Items.length} translations for ${language}`);
    
    // Add validation for translation items
    const translations = result.Items.reduce((acc, item) => {
      if (item.key && item.value && typeof item.value === 'string') {
        acc[item.key] = item.value;
      } else {
        console.warn(`Invalid translation item found for ${language}:`, item);
      }
      return acc;
    }, {});

    return Object.keys(translations).length > 0 ? translations : null;
  } catch (error) {
    console.error(`Error fetching translations for ${language}:`, error);
    throw error;
  }
};

export const initializeI18n = async () => {
  const loadingPromises = [];
  const loadedLanguages = new Set();

  try {
    // Load all supported languages in parallel
    for (const language of SUPPORTED_LANGUAGES) {
      const loadLanguage = async () => {
        try {
          const translations = await fetchTranslationsFromDynamoDB(language);
          if (translations) {
            i18n.addResourceBundle(language, 'translation', translations, true, true);
            loadedLanguages.add(language);
          } else {
            console.warn(`No translations found for ${language}`);
          }
        } catch (error) {
          console.error(`Failed to load ${language} translations:`, error);
        }
      };
      loadingPromises.push(loadLanguage());
    }

    // Wait for all languages to load
    await Promise.all(loadingPromises);

    // Verify that at least the default and fallback languages are loaded
    if (!loadedLanguages.has(DEFAULT_LANGUAGE)) {
      console.error(`Failed to load default language (${DEFAULT_LANGUAGE})`);
    }
    if (!loadedLanguages.has(FALLBACK_LANGUAGE)) {
      console.error(`Failed to load fallback language (${FALLBACK_LANGUAGE})`);
    }

    return i18n;
  } catch (error) {
    console.error('Failed to initialize i18n:', error);
    throw error;
  }
};

export const changeLanguage = async (language) => {
  if (!SUPPORTED_LANGUAGES.includes(language)) {
    throw new Error(`Unsupported language: ${language}`);
  }

  try {
    // Check if translations are already loaded
    if (!i18n.hasResourceBundle(language, 'translation')) {
      console.log(`Loading translations for ${language}`);
      const translations = await fetchTranslationsFromDynamoDB(language);
      
      if (translations) {
        i18n.addResourceBundle(language, 'translation', translations, true, true);
        console.log(`Successfully loaded translations for ${language}`);
      } else {
        console.warn(`No translations available for ${language}, falling back to ${FALLBACK_LANGUAGE}`);
        
        // Load fallback language if needed
        if (!i18n.hasResourceBundle(FALLBACK_LANGUAGE, 'translation')) {
          const fallbackTranslations = await fetchTranslationsFromDynamoDB(FALLBACK_LANGUAGE);
          if (fallbackTranslations) {
            i18n.addResourceBundle(FALLBACK_LANGUAGE, 'translation', fallbackTranslations, true, true);
          }
        }
      }
    }

    await i18n.changeLanguage(language);
    console.log(`Language changed to: ${i18n.language}`);
    return true;
  } catch (error) {
    console.error(`Error changing language to ${language}:`, error);
    throw error;
  }
};

export default i18n;