import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const [scrollToSection, setScrollToSection] = useState(() => () => {});

  return (
    <ScrollContext.Provider value={{ scrollToSection, setScrollToSection }}>
      {children}
    </ScrollContext.Provider>
  );
};