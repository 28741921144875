import React from 'react';
import { useAppContext } from '../AppContext';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { t } = useTranslation();
    const { dispatch } = useAppContext();
 
    const changeLanguage = (lang) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lang });
    };
  
    return (
      <div className="relative group">
        <button className="flex items-center text-gray-600 hover:text-blue-500">
          {t('currentLanguage')}
        </button>
        <div className="absolute right-0 mt-0 py-2 w-48 bg-white rounded-md shadow-xl z-20 hidden group-hover:block">
        <button
        onClick={() => changeLanguage('ptbr')}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white w-full text-left">
        🇧🇷 Português</button>
        <button
        onClick={() => changeLanguage('en')}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white w-full text-left">
        🇺🇸 English</button>
        <button
        onClick={() => changeLanguage('es')}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white w-full text-left">
        🇪🇸 Español</button>
        </div>
      </div>
    );
  };

  export default LanguageSwitcher;