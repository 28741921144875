import React from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';

const PricingModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
  
    if (!isOpen) return null;
  
    return (

      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-8 rounded-lg max-w-2xl w-full m-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">{t('pricingTitle')}</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>
          <div className="space-y-6">
            <div className="border-b pb-4">
              <h3 className="text-xl font-semibold mb-2">{t('essentialPlan')}</h3>
              <p className="text-gray-600 mb-2">{t('essentialPlanDesc')}</p>
              <p className="text-2xl font-bold">{t('essentialPlanPrice')}</p>
            </div>
            <div className="border-b pb-4">
              <h3 className="text-xl font-semibold mb-2">{t('advancedPlan')}</h3>
              <p className="text-gray-600 mb-2">{t('advancedPlanDesc')}</p>
              <p className="text-2xl font-bold">{t('advancedPlanPrice')}</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">{t('corporatePlan')}</h3>
              <p className="text-gray-600 mb-2">{t('corporatePlanDesc')}</p>
              <p className="text-2xl font-bold">{t('corporatePlanPrice')}</p>
            </div>
          </div>
          <button 
            onClick={onClose}
            className="mt-8 w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            {t('close')}
          </button>
        </div>
      </div>
    );
  };

  export default PricingModal;