import React, { Suspense } from 'react';
import { AppProvider } from './AppContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import { ScrollProvider } from './components/ScrollContext';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const JoinPage = React.lazy(() => import('./pages/Join'));
const LoginPage = React.lazy(() => import('./pages/Login'));
const ApplicationPage = React.lazy(() => import('./pages/Easybudget'));
const BusinessFinancePage = React.lazy(() => import('./pages/BusinessFinancePage'));
const PersonalSavingsPage = React.lazy(() => import('./pages/PersonalSavingsPage'));
const InvestmentStrategiesPage = React.lazy(() => import('./pages/InvestmentStrategiesPage'));
const SolutionsPage = React.lazy(() => import('./pages/Solutions'))
const PrivacyPage = React.lazy(() => import('./pages/Privacy'))
const TermsPage = React.lazy(() => import('./pages/Terms'))
const ContactPage = React.lazy(() => import('./pages/Contact'))
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));
const ProtectedRoute = React.lazy(() => import('./components/ProtectedRoute'));
const PublicOnlyRoute = React.lazy(() => import('./components/PublicOnlyRoute'));

// Loading Spinner Component
const LoadingSpinner = () => (
  <div className="h-screen w-full flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
  </div>
);

// Main App Content Component
const AppContent = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <Header />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/business-finance" element={<BusinessFinancePage />} />
          <Route path="/personal-savings" element={<PersonalSavingsPage />} />
          <Route path="/investment-strategies" element={<InvestmentStrategiesPage />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/contact" element={<ContactPage />} />

          {/* Auth Routes */}
          <Route 
            path="/join" 
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PublicOnlyRoute>
                  <JoinPage />
                </PublicOnlyRoute>
              </Suspense>
            } 
          />
          <Route 
            path="/login" 
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PublicOnlyRoute>
                  <LoginPage />
                </PublicOnlyRoute>
              </Suspense>
            } 
          />

          {/* Protected Routes */}
          <Route 
            path="/easybudget/*" 
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ProtectedRoute>
                  <ApplicationPage />
                </ProtectedRoute>
              </Suspense>
            } 
          />

          {/* Catch-all Route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
};

// Main App Component
const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppProvider>
          <ScrollProvider>
            <AppContent />
          </ScrollProvider>
        </AppProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;








