import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useScrollContext } from './ScrollContext';
import LanguageSwitcher from './LanguageSwitcher';
import PricingModal from './PricingModal';

const Header = ({ solutionsRef }) => {
    const { t } = useTranslation();
    const [isPricingOpen, setIsPricingOpen] = useState(false);
    const { scrollToSection } = useScrollContext();

    const handlePricingClick = () => {
        setIsPricingOpen(true);
    };

    const handleClosePricing = () => {
        setIsPricingOpen(false);
      };
  
    return (
      <header className="bg-white shadow-sm">
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/">
              <img src="/img/logo_eb_simple.png" alt="EasyBudget - Controle Financeiro ao seu alcance" className="h-8 w-auto mr-2" />
            </Link>
          </div>
          <div className="space-x-4 flex items-center">
            <button onClick={() => scrollToSection('solutions')} className="text-gray-600 hover:text-blue-500">{t('solutions')}</button>
            <button onClick={handlePricingClick} className="text-gray-600 hover:text-blue-500">{t('pricing')}</button>
            <button onClick={() => scrollToSection('about')} className="text-gray-600 hover:text-blue-500">{t('about')}</button>
            <LanguageSwitcher />
            <Link to="/join" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">{t('joinNow')}</Link>
          </div>
        </nav>
        <PricingModal isOpen={isPricingOpen} onClose={handleClosePricing} />
      </header>
      
    );
  };
  
  export default Header;