import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
        <footer className="bg-gray-800 text-white py-8">
          <div className="container mx-auto px-6 flex flex-wrap justify-between items-center">
            <div className="text-sm">© 2024 Easybudget.{t('allRights')}</div>
            <div className="space-x-4">
              <a href="/privacy" className="hover:text-blue-300">{t('privacyPolicy')}</a>
              <a href="/terms" className="hover:text-blue-300">{t('termsOfService')}</a>
              <a href="/contact" className="hover:text-blue-300">{t('contact')}</a>
            </div>
          </div>
        </footer>
      </div>
    );
  };

export default Footer;
